import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function usePOList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refPOTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'caseID',
      label: 'REQUEST / CASE NUMBER',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'daTE COMPLETED',
      sortable: true,
    },
    {
      key: 'entity',
      label: 'ENTITY',
      sortable: true,
    },
    {
      key: 'requestedBy',
      label: 'Requested by',
      sortable: true,
    },
    {
      key: 'amount',
      label: 'Amount (S$)',
      sortable: true,
    },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalPO = ref(0)
  const searchQuery = ref(route.query.search || '')
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(route.query.sortBy ?? 'itemNo')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const toDeletedID = ref(null)
  const poData = ref([])
  let initialLoad = true
  const currentDataLength = ref(0)
  const vendorFilter = ref(route.params.id || null)

  const dataMeta = computed(() => {
    const localItemsCount = refPOTable.value ? refPOTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPO.value,
    }
  })

  const refetchData = () => {
    refPOTable.value.refresh()
  }

  watch([searchQuery, currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchPO = (ctx, callback) => {
    store
      .dispatch('app-purchase-order/fetchPO', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        vendorFilter: vendorFilter.value,
      },
      { root: true })
      .then(response => {
        const { purchaseOrders, total } = response.data
        poData.value = purchaseOrders
        currentDataLength.value = purchaseOrders.length
        callback(purchaseOrders)
        totalPO.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              vendorFilter: vendorFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deletePO = () => {
    store
      .dispatch('app-purchase-order/deletePO', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePOstatusVariant = status => {
    if (status === 'published') return 'success'
    if (status === 'pending') return 'warning'
    if (status === 'hidden') return 'secondary'
    return 'primary'
  }

  return {
    fetchPO,
    tableColumns,
    perPage,
    currentPage,
    poData,
    totalPO,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refPOTable,

    searchQuery,

    resolvePOstatusVariant,
    refetchData,
    currentDataLength,

    toDeletedID,
    deletePO,
  }
}
