<template>

  <div class="service_form_details">
    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Company General Information</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="canViewThisAction('update', 'Vendor')"
            variant="flat-primary"
            @click="confirmBeforeEdit('company_detail')"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Registration No.
            </b-th>
            <b-td>{{ vendor.companyRegistration ? vendor.companyRegistration : '-' }}</b-td>
            <b-th width="230px">
              Tel
            </b-th>
            <b-td>{{ vendor.companyContact ? vendor.companyContact : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              GST Registration No.
            </b-th>
            <b-td>{{ vendor.companyGSTRegistration ? vendor.companyGSTRegistration : '-' }}</b-td>
            <b-th width="230px">
              Fax
            </b-th>
            <b-td>{{ vendor.companyFax ? vendor.companyFax : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              NRIC No. (For Individual)
            </b-th>
            <b-td>{{ vendor.individualNRIC ? vendor.individualNRIC : '-' }}</b-td>
            <b-th width="230px">
              Address
            </b-th>
            <b-td>{{ vendor.companyAddress ? vendor.companyAddress : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Sales Person Information</h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th style="width:360px">
              Name
            </b-th>
            <b-td>{{ vendor.salesPersonName ? vendor.salesPersonName : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Contact No.
            </b-th>
            <b-td>{{ vendor.salesPersonContact ? vendor.salesPersonContact : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Email Address
            </b-th>
            <b-td>{{ vendor.salesPersonEmail ? vendor.salesPersonEmail : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Email Address 2
            </b-th>
            <b-td>{{ vendor.salesPersonEmail2 ? vendor.salesPersonEmail2 : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Any Related Family Parties Working In NV Group?
            </b-th>
            <b-td class="text-capitalize">
              {{ vendor.relativeInNirvana ? vendor.relativeInNirvana : '-' }}
              <br>
              <span v-if="vendor.relativeInNirvana == 'yes'"> {{ vendor.relativeDetail ? vendor.relativeDetail : '-' }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h4>
            <span class="align-middle">Product / Service Provided and Payment Terms</span>
          </h4>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th style="width:360px">
              Type of Product/Service Provided
            </b-th>
            <b-td>{{ vendor.providedServiceType ? vendor.providedServiceType : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th style="width:360px">
              Payment Terms
            </b-th>
            <b-td>{{ resolvePaymentTerms(vendor.paymentTerms) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Banking Information</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="canViewThisAction('update', 'Vendor')"
            variant="flat-primary"
            @click="confirmBeforeEdit('banking_detail')"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Account Payee Name
            </b-th>
            <b-td>{{ vendor.accountPayeeName ? vendor.accountPayeeName : '-' }}</b-td>
            <b-th width="230px">
              Bank Account No.
            </b-th>
            <b-td>{{ vendor.bankAccountNo ? vendor.bankAccountNo : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Bank Name
            </b-th>
            <b-td>{{ vendor.bankName ? vendor.bankName : '-' }}</b-td>
            <b-th width="230px">
              Address
            </b-th>
            <b-td>{{ vendor.bankAddress ? vendor.bankAddress : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Bank Branch
            </b-th>
            <b-td>{{ vendor.bankBranch ? vendor.bankBranch : '-' }}</b-td>
            <b-th width="230px">
              Email Address
            </b-th>
            <b-td>{{ vendor.bankEmail ? vendor.bankEmail : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Swift Code (For Overseas)
            </b-th>
            <b-td>{{ vendor.swiftCode ? vendor.swiftCode : '-' }}</b-td>
            <b-th width="230px">
              Paynow Details
            </b-th>
            <b-td>{{ vendor.paynowNo ? vendor.paynowNo : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Certifications Obtained</h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            v-if="canViewThisAction('update', 'Vendor')"
            variant="flat-primary"
            @click="confirmBeforeEdit('certification_detail')"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Certifications
            </b-th>
            <b-td
              class="certificate_col"
            >
              <span
                v-for="(certificate, key) in vendor.certificates"
                :key="key"
                class="content-header-left mb-2"
                cols="12"
                md="4"
              >
                <span v-if="certificate == 'Others'">
                  {{ vendor.otherCertificates }}
                </span>
                <span v-else>
                  {{ certificate }}
                </span>
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Attachments</h3>
        </b-col>
      </b-row>
      <b-row>
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in vendor.attachments"
          :key="key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <!-- <span class="remove__doc">
              <b-button
                variant="flat-primary"
                @click="removeAttachment(file.data)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span> -->
          </div>
          <div class="doc__desc">
            <span v-if="file.description">
              {{ file.description }}
            </span>
            <span v-else>
              <button @click="updateDescription(key)">Add a description</button>
            </span>
            <span class="edit__desc">
              <b-button
                v-if="file.description"
                variant="flat-primary"
                @click="updateDescription(key)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                  size="18"
                />
              </b-button>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <div class="without__bg">
      <div class="signatories_block pt-2">
        <b-row class="content-header">
          <b-col
            v-for="(signatory, index) of vendor.signatories"
            :key="index"
            cols="3"
            md="3"
            class="mt-md-0 mt-3"
            order="2"
            order-md="1"
          >
            <b-card-text class="mb-0">
              <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
              <div class="sign-box">
                <div v-if="signatory.user">
                  <b-avatar
                    v-if="signatory.user.image"
                    :src="signatory.user.image"
                  />
                  <b-avatar
                    v-else
                    :text="resolveAcronymName(signatory.user.name)"
                  />
                </div>
                <div v-if="signatory.signed == true">
                  <!-- <div v-if="signatory.user && signatory.user.signature">
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar"
                      height="50"
                      :src="signatory.user.signature"
                    />
                  </div> -->
                  <div v-if="signatory.user && signatory.user.name">
                    <span>{{ signatory.user.name }}</span>
                  </div>
                </div>
                <div v-else>
                  <p>Pending Signature</p>
                </div>
              </div>
              <p class="ml-75 semi-bold">
                {{ signatory.user ? signatory.user.name : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
              </p>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg, BFormTextarea, BLink, BCardText,
  BAvatar,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BFormTextarea,
    BLink,
    BCardText,
    BAvatar,
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canViewThisAction,
      vendorSignatories: [],
      vendorID: '',
      stringID: '',
      status: 'approved',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Approved', code: 'approved' },
        { title: 'Pending', code: 'pending' },
        { title: 'Non Approved', code: 'non approved' },
      ],
      description: '',
      attachmentKey: '',
      required,
      emailToOptions: [],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  methods: {
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.vendor.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolvePaymentTerms(val) {
      let text = ''
      if (val === 'Others') {
        text = this.vendor.paymentTermsDescription
      } else {
        text = `${val}`
      }

      return text
    },
    confirmBeforeEdit(section) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing this vendor will reset the approval process and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, I am sure',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-vendors-edit', params: { id: this.$route.params.id }, query: { section } })
          }
        })
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/vendors/remove-attachment', { url, vendor: this.$route.params.id })
        .then(response => {
          this.$emit('update:vendor', response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.vendor.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/vendors/save-description', { url, description: this.description, vendor: this.$route.params.id })
        .then(response => {
          this.$emit('update:vendor', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setData() {
      this.vendorID = this.vendor._id
      this.status = this.vendor.status
      this.stringID = this.vendor.stringID
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
