<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="vendor-detail-header">
          <b-col
            cols="md-8"
            class="mb-1"
          >
            <h1 class="custom-header-title">
              {{ vendor.stringID }} - {{ vendor.companyName }}
            </h1>
            <p class="log_info">
              Created by {{ vendor.createdBy ? vendor.createdBy.name : '' }} on {{ dateFormatWithTime(vendor.createdAt) }}<br>Last updated on {{ dateFormatWithTime(vendor.updatedAt) }}
            </p>
          </b-col>
          <b-col
            class="content-header-right text-md-right mb-1"
            cols="md-4"
          >
            <div class="user_block ac_type">
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Status</h6>
                <h5 class="text-capitalize">
                  {{ vendor.status ? vendor.status.replace("-", " ") : '' }}
                </h5>
              </div>
            </div>
            <div class="user_block pr_stage_type">
              <div class="user_avatar" />
              <div class="user_info">
                <h6>Ranking</h6>
                <h5 class="text-capitalize">
                  {{ vendor.rank ? vendor.rank : 0 }}
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeTab(tab.index)"
          >
            {{ tab.title }}
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-if="isMobile() && canViewThisAction('update', 'Vendor')"
          variant="outline-primary"
          no-caret
          class="text-right ml-auto vendor-detail-dd"
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="canViewThisAction('update', 'Vendor') && vendor.status != 'draft'"
            v-b-modal.vendor-all-modal-update-status
          >
            <span class="align-middle">Update Vendor Status</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canViewThisAction('update', 'Vendor') && vendor.status == 'draft' && vendor.createdBy && vendor.createdBy._id == user._id"
            :to="{ name: 'purchasing-vendors-preview', params: { id: $route.params.id } }"
          >
            <span class="align-middle">Submit Vendor for Approval</span>
          </b-dropdown-item>

          <div v-if="canViewThisAction('create', 'Vendor')">
            <b-dropdown-item
              v-if="vendor.status == 'draft' || vendor.status == 'incoming'"
              @click="generateOnlineLink(vendor._id)"
            >
              <span class="align-middle">Create an online link for this Vendor</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              disabled
            >
              <span class="align-middle">Create an online link for this Vendor</span>
            </b-dropdown-item>
          </div>

          <b-dropdown-item
            v-if="canViewThisAction('update', 'Vendor')"
            :to="{ name: 'purchasing-vendors-edit', params: { id: vendor._id ? vendor._id : 0 } }"
          >
            <span class="align-middle">Edit Vendor</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          v-model="tabIndex"
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            lazy
            @click="changeTab(0)"
          >
            <template #title>
              <span class="d-none d-sm-inline">Company General Information</span>
            </template>
            <vendor-information-page
              class="mt-2 pt-75"
              :vendor.sync="vendor"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            lazy
            @click="changeTab(1)"
          >
            <template #title>
              <span class="d-none d-sm-inline">Goods & Services</span>
            </template>
            <items-master-list
              class="mt-2 pt-75"
              :vendor="vendor"
            />
          </b-tab>

          <b-tab
            lazy
            @click="changeTab(2)"
          >
            <template #title>
              <span class="d-none d-sm-inline">Past Purchases</span>
            </template>
            <purchase-order-list
              class="mt-2 pt-75"
              :vendor="vendor"
            />
          </b-tab>

          <b-tab
            lazy
            @click="changeTab(3)"
          >
            <template #title>
              <span class="d-none d-sm-inline">Non-Conformance</span>
            </template>
            <non-conformance-list
              class="mt-2 pt-75"
              :vendor="vendor"
            />
          </b-tab>

          <b-tab
            lazy
            @click="changeTab(4)"
          >
            <template #title>
              <span class="d-none d-sm-inline">Past Evaluations</span>
            </template>
            <evaluation-list
              class="mt-2 pt-75"
              :vendor="vendor"
            />
          </b-tab>

          <template #tabs-end>
            <b-dropdown
              v-if="canViewThisAction('update', 'Vendor')"
              variant="outline-primary"
              no-caret
              class="text-right ml-auto vendor-detail-more-icon"
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Vendor') && vendor.status != 'draft'"
                v-b-modal.vendor-all-modal-update-status
              >
                <span class="align-middle">Update Vendor Status</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Vendor') && vendor.status == 'draft' && vendor.createdBy && vendor.createdBy._id == user._id"
                :to="{ name: 'purchasing-vendors-preview', params: { id: $route.params.id } }"
              >
                <span class="align-middle">Submit Vendor for Approval</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item
                v-else-if="canViewThisAction('update', 'Vendor') && vendor.status != 'draft'"
                v-b-modal.vendor-all-modal-update-status
              >
                <span class="align-middle">Update Vendor Status</span>
              </b-dropdown-item> -->
              <div v-if="canViewThisAction('create', 'Vendor')">
                <b-dropdown-item
                  v-if="vendor.status == 'draft' || vendor.status == 'incoming'"
                  @click="generateOnlineLink(vendor._id)"
                >
                  <span class="align-middle">Create an online link for this Vendor</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-else
                  disabled
                >
                  <span class="align-middle">Create an online link for this Vendor</span>
                </b-dropdown-item>
              </div>

              <b-dropdown-item
                v-if="canViewThisAction('update', 'Vendor')"
                :to="{ name: 'purchasing-vendors-edit', params: { id: vendor._id ? vendor._id : 0 } }"
              >
                <span class="align-middle">Edit Vendor</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-tabs>
      </b-col>
    </b-row>
    <b-modal
      id="vendor-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="allVendorStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                placeholder="Please Select The Option"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BTab, BTabs, BDropdown, BDropdownItem, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VendorInformationPage from './VendorInformationPage.vue'
import ItemsMasterList from './master-item/ItemsMasterList.vue'
import PurchaseOrderList from './purchase-order/PurchaseOrderList.vue'
import EvaluationList from './previous-evaluation/EvaluationList.vue'
import NonConformanceList from './non-conformance/NonConformanceList.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    vSelect,
    ValidationProvider,
    ValidationObserver,

    VendorInformationPage,
    ItemsMasterList,
    PurchaseOrderList,
    EvaluationList,
    NonConformanceList,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      vendor: {},
      currentTabTitle: 'Company General Information',
      tabIndex: 0,
      pageTabs: [
        {
          title: 'Company General Information',
          index: 0,
        },
        {
          title: 'Goods & Services',
          index: 1,
        },
        {
          title: 'Past Purchases',
          index: 2,
        },
        {
          title: 'Non-Conformance',
          index: 3,
        },
        {
          title: 'Past Evaluations',
          index: 4,
        },
      ],
      vendorSignatories: [],
      vendorID: '',
      stringID: '',
      status: '',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Approved', code: 'approved' },
        { title: 'Pending', code: 'pending' },
        { title: 'Non Approved', code: 'non approved' },
      ],
      description: '',
      attachmentKey: '',
      emailToOptions: [],
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  beforeMount() {
    this.$http.get(`purchase/vendors/${this.$route.params.id}/show`)
      .then(response => {
        this.vendor = response.data.data || {}
        this.vendorSignatories = response.data.vendorSignatories || []

        this.setData()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeTab(index) {
      if (this.$router.query) {
        this.$router.replace({
          query: {},
        })
      }
      this.tabIndex = index
      this.currentTabTitle = this.pageTabs[index].title
    },
    generateOnlineLink(id) {
      const link = `${window.location.origin}/vendor/self-registration/${id}?referrer=${this.user._id}`
      this.$swal({
        title: 'Link Generated',
        html: `The vendor can now fill up the New Vendor Registration Form by accessing this link below: <br> ${link}`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/link.png'),
        showCancelButton: false,
        confirmButtonText: 'Copy Link',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        allowOutsideClick: false,
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$copyText(link).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Text copied',
                  icon: 'BellIcon',
                },
              })
            }, () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Can not copy!',
                  icon: 'BellIcon',
                },
              })
            })
          }
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.vendor.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolvePaymentTerms(val) {
      let text = ''
      if (val === 'Others') {
        text = this.vendor.paymentTermsDescription
      } else {
        text = `${val}`
      }

      return text
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/vendors/remove-attachment', { url, vendor: this.$route.params.id })
        .then(response => {
          this.vendor = response.data.data || {}
          this.setData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.vendor.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/vendors/save-description', { url, description: this.description, vendor: this.$route.params.id })
        .then(response => {
          this.vendor = response.data.data || {}
          this.setData()
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setData() {
      this.vendorID = this.vendor._id
      this.stringID = this.vendor.stringID
      if (this.vendor.status === 'pending') {
        this.statusDataOptions = [
          { title: 'Draft', code: 'draft' },
          { title: 'Non Approved', code: 'non approved' },
        ]
      } else if (this.vendor.status === 'approved') {
        this.statusDataOptions = [
          { title: 'Draft', code: 'draft' },
          { title: 'Non Approved', code: 'non approved' },
        ]
      } else if (this.vendor.status === 'non approved') {
        this.statusDataOptions = [
          { title: 'Draft', code: 'draft' },
        ]
      } else if (this.vendor.status === 'draft') {
        this.statusDataOptions = [
          { title: 'Pending', code: 'pending' },
        ]
      }
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    submitForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.allVendorStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`purchase/vendors/${this.vendorID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.vendor.status = response.data.data.status || 'pending'
              this.$root.$emit('bv::hide::modal', 'vendor-all-modal-update-status', '')
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(() => {
                  this.$router.push({ name: 'purchasing-vendors-index', params: { type: 'all-vendors' } })
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
